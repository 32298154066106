export const useBlogList = async (queryString) => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(
    () => `posts${queryString.value}&lang=${blogLocale.value}&site=${useGlobalStore().siteCurrentLocale}`,
    {
      baseURL: useRuntimeConfig().public.apiBlogBase,
    }
  );
};

export const useBlogLatest = async (queryString) => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(() => `latest?lang=${blogLocale.value}&site=${useGlobalStore().siteCurrentLocale}`, {
    baseURL: useRuntimeConfig().public.apiBlogBase,
  });
};

export const useBlogDetail = async (slug) => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(() => `post`, {
    baseURL: useRuntimeConfig().public.apiBlogBase,
    params: { slug: slug, lang: blogLocale.value, site: useGlobalStore().siteCurrentLocale },
  });
};

export const useBlogAuthorSlug = async (slug) => {
  return useFetchSafely((authorData) => `author?slug=${slug}&site=${useGlobalStore().siteCurrentLocale}`, {
    baseURL: useRuntimeConfig().public.apiBlogBase,
  });
};

export const useCategoryList = async () => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(() => `categories`, {
    baseURL: useRuntimeConfig().public.apiBlogBase,
    params: { hide_empty: true, lang: blogLocale.value, site: useGlobalStore().siteCurrentLocale },
  });
};

export const useCategoryDetail = async (slug) => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(() => `category`, {
    baseURL: useRuntimeConfig().public.apiBlogBase,
    params: { slug: slug, lang: blogLocale.value, site: useGlobalStore().siteCurrentLocale },
  });
};

export const useTagDetail = async (slug) => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(() => `tag`, {
    baseURL: useRuntimeConfig().public.apiBlogBase,
    params: { slug: slug, lang: blogLocale.value, site: useGlobalStore().siteCurrentLocale },
  });
};

export const useAuthorList = async (queryString) => {
  const blogLocale = useBlogLocale();
  return useFetchSafely(
    () => `authors${queryString.value}&lang=${blogLocale.value}&site=${useGlobalStore().siteCurrentLocale}`,
    {
      baseURL: useRuntimeConfig().public.apiBlogBase,
    }
  );
};
